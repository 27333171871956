<template>
  <admin-component />
</template>

<script>
import adminComponent from "../../components/Admin/adminComponent.vue";
export default {
  components: { adminComponent },
  name: "AdminView",
};
</script>

<style>
</style>