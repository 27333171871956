<template>
  <v-row no-gutters>
    <admin-navigation-drawer @changeActiveItem="handleActiveItem" />
    <div style="background-color: #fafafa; width: calc(100% - 256px)">
      <admin-header :title="headerTitle" />

      <v-col style="padding: 0px 20px 40px 20px">
        <v-expand-transition>
          <router-view />
        </v-expand-transition>
      </v-col>

      <!-- <v-col style="padding: 0px 20px 40px 20px">
        <v-expand-transition>
          <main-component v-if="activeItem == 'main'" />
        </v-expand-transition>
        <v-expand-transition>
          <about-us-component v-if="activeItem == 'about_us'" />
        </v-expand-transition>
        <v-expand-transition>
          <contact-component v-if="activeItem == 'contact'" />
        </v-expand-transition>
        <v-expand-transition>
          <reviews-component v-if="activeItem == 'reviews'" />
        </v-expand-transition>
        <v-expand-transition>
          <FAQ-component v-if="activeItem == 'FAQ'" />
        </v-expand-transition>
        <v-expand-transition>
          <reports-component v-if="activeItem == 'reports'" />
        </v-expand-transition>
        <v-expand-transition>
          <routes-component
            v-if="activeItem == 'route'"
            @createNewRoute="headerTitle = 'Додати маршрут'"
            @editRoute="setHeaderTitle"
            @back="headerTitle = 'Маршрути'"
          />
        </v-expand-transition>
        <v-expand-transition>
          <prices-component v-if="activeItem == 'price_table'" />
        </v-expand-transition>
        <v-expand-transition>
          <cities-component v-if="activeItem == 'cities'" />
        </v-expand-transition>
        <v-expand-transition>
          <stations-component v-if="activeItem == 'stations'" />
        </v-expand-transition>
        <v-expand-transition>
          <trips-component v-if="activeItem == 'trip'" />
        </v-expand-transition>
        <v-expand-transition>
          <users-component v-if="activeItem == 'users'" />
        </v-expand-transition>
        <v-expand-transition>
          <autopark-component v-if="activeItem == 'autopark'" />
        </v-expand-transition>
        <v-expand-transition>
          <blog-component v-if="activeItem == 'blog'" />
        </v-expand-transition>
        <v-expand-transition>
          <message-component v-if="activeItem == 'messages'" />
        </v-expand-transition>
        <v-expand-transition>
          <promotion-component v-if="activeItem == 'promocion'" />
        </v-expand-transition>
        <v-expand-transition>
          <promocode-component v-if="activeItem == 'promocode'" />
        </v-expand-transition>
        <v-expand-transition>
          <social-component v-if="activeItem == 'social'" />
        </v-expand-transition>
        <v-expand-transition>
          <workers-component v-if="activeItem == 'worker'" />
        </v-expand-transition>
        <v-expand-transition>
          <setting-admin v-if="activeItem == 'setting'" />
        </v-expand-transition>
      </v-col> -->
    </div>
  </v-row>
</template>

<script>
// import mainComponent from "./Main/mainCharts/mainComponent.vue";
// import AboutUsComponent from "./Main/aboutUsComponent.vue";
// import ContactComponent from "./Main/contactComponent.vue";
// import CitiesComponent from "./Routes/Cities/citiesComponent.vue";
// import RoutesComponent from "./Routes/routesComponent.vue";
// import PricesComponent from "./Routes/Prices/pricesComponent.vue";
// import TripsComponent from "./Trips/tripsComponent.vue";
// import ReviewsComponent from "./Main/Reviews/reviewsComponent.vue";
// import FAQComponent from "./Main/FAQ/FAQComponent.vue";
// import AutoparkComponent from "./Autopark/autoparkComponent.vue";
// import BlogComponent from "./Blog/blogComponent.vue";
// import MessageComponent from "./Message/messageComponent.vue";
// import PromotionComponent from "./Promotions/promotionComponent.vue";
// import PromocodeComponent from "./Promotions/Promocode/promocodeComponent.vue";
// import StationsComponent from "./Routes/Stations/stationsComponent.vue";
// import socialComponent from "./Promotions/Social/socialComponent.vue";
// import usersComponent from "./Users/usersComponent.vue";
// import SettingAdmin from "./settingAdmin.vue";
// import workersComponent from "./Workers/workersComponent.vue";
// import ReportsComponent from './Reports/reportsComponent.vue';
import AdminHeader from "./adminHeader.vue";
import adminNavigationDrawer from "./adminNavigationDrawer.vue";
export default {
  components: {
    adminNavigationDrawer,
    AdminHeader,
    // mainComponent,
    // AboutUsComponent,
    // ContactComponent,
    // CitiesComponent,
    // RoutesComponent,
    // PricesComponent,
    // TripsComponent,
    // ReviewsComponent,
    // FAQComponent,
    // AutoparkComponent,
    // BlogComponent,
    // MessageComponent,
    // PromotionComponent,
    // PromocodeComponent,
    // StationsComponent,
    // socialComponent,
    // usersComponent,
    // SettingAdmin,
    // workersComponent,
    // ReportsComponent,
  },
  data: () => ({
    activeItem: "main",
    headerTitle: "Головна",
  }),
  methods: {
    handleActiveItem(activeItem) {
      this.$vuetify.goTo(0, 0);
      this.activeItem = activeItem;
      switch (this.activeItem) {
        case "mainComponent":
          {
            this.headerTitle = "Головна";
          }
          break;
        case "aboutUsComponent":
          {
            this.headerTitle = "Про нас";
          }
          break;
        case "contactComponent":
          {
            this.headerTitle = "Контакти";
          }
          break;
        case "reviewsComponent":
          {
            this.headerTitle = "Відгуки";
          }
          break;
        case "our_plus":
          {
            this.headerTitle = "Наші переваги";
          }
          break;
        case "faqComponent":
          {
            this.headerTitle = "Поширені запитання";
          }
          break;
        case "reportsComponent":
          {
            this.headerTitle = "Звітність";
          }
          break;
        case "routesComponent":
          {
            this.headerTitle = "Маршрути";
          }
          break;
        case "pricesComponent":
          {
            this.headerTitle = "Таблиця цін";
          }
          break;
        case "citiesComponent":
          {
            this.headerTitle = "Міста";
          }
          break;
        case "stationsComponent":
          {
            this.headerTitle = "Зупинки";
          }
          break;

        case "tripsComponent":
          {
            this.headerTitle = "Квитки на маршрути";
          }
          break;
        case "history_purchase":
          {
            this.headerTitle = "Історія продажу";
          }
          break;
        case "usersComponent":
          {
            this.headerTitle = "База клієнтів";
          }
          break;
        case "autoparkComponent":
          {
            this.headerTitle = "Автопарк";
          }
          break;
        case "blogComponent":
          {
            this.headerTitle = "Блог";
          }
          break;
        case "messageComponent":
          {
            this.headerTitle = "Повідомлення";
          }
          break;
        case "promotionComponent":
          {
            this.headerTitle = "Знижки";
          }
          break;
        case "promocodeComponent":
          {
            this.headerTitle = "Промокоди";
          }
          break;
        case "socialComponent":
          {
            this.headerTitle = "Пільги";
          }
          break;
        case "liqpayComponent":
          {
            this.headerTitle = "Налаштування платіжної системи LiqPay";
          }
          break;
        case "blinkComponent":
          {
            this.headerTitle = "Налаштування платіжної системи Blink";
          }
          break;
        case "workerComponent":
          {
            this.headerTitle = "Працівники";
          }
          break;
        case "settingComponent":
          {
            this.headerTitle = "Налаштування";
          }
          break;
      }
    },
    setHeaderTitle(title) {
      this.headerTitle = title;
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>